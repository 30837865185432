import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img3.jpg";
import projImg3 from "../assets/img/project-img2.jpg";
// import projImg4 from "../assets/img/aisa.png";

import projImg4 from "../assets/img/dss1.JPG";
import projImg5 from "../assets/img/dss2.JPG";
import projImg6 from "../assets/img/dss3.JPG";
import projImg7 from "../assets/img/viz1.JPG";
import projImg8 from "../assets/img/viz2.jpg";
import projImg9 from "../assets/img/viz3.jpg";

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React, { useState } from 'react';

export const Projects = () => {
  const [selectedTab, setSelectedTab] = useState('first');

  // Function to fetch data for the selected tab
  const fetchDataForTab = (tabKey) => {
    // Add logic to fetch data based on the tab key
    // Placeholder data for demonstration
    if (tabKey === 'first') {
      return [
        {
          title: <a href="https://vizathon.aisa-viit.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none',textShadow: '2px 2px 4px black' }}>Viz-A-Thon</a>,
          imgUrl: projImg7,

        },
        {
          title: <a href="https://vizathon.aisa-viit.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none',textShadow: '2px 2px 4px black' }}>Viz-A-Thon</a>,
          imgUrl: projImg8,
        },
        {
          title: <a href="https://vizathon.aisa-viit.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none',textShadow: '2px 2px 4px black' }}>Viz-A-Thon</a>,
          imgUrl: projImg9,
        },
      ];
    } else if (tabKey === 'section') {
      // Return data for the 'section' tab
      return [
        {
          title: <a href="https://perception.aisa-viit.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none',textShadow: '2px 2px 4px black' }}>Perception</a>,
          imgUrl: projImg1,
        },
        {
          title: <a href="https://perception.aisa-viit.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none',textShadow: '2px 2px 4px black' }}>Perception</a>,
          imgUrl: projImg2,
        },
        {
          title: <a href="https://perception.aisa-viit.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none',textShadow: '2px 2px 4px black' }}>Perception</a>,
          imgUrl: projImg3,
        },
        // Add more objects for 'Perception' as needed
      ];
    } else if (tabKey === 'third') {
      // Return data for the 'third' tab
      return [
        {
          title: <a href="https://dss.aisa-viit.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none',textShadow: '2px 2px 4px black' }}>DSS</a>,
          imgUrl: projImg4,
        },

        {
          title: <a href="https://dss.aisa-viit.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none',textShadow: '2px 2px 4px black' }}>DSS</a>,
          imgUrl: projImg5,
        },
        {
          title: <a href="https://dss.aisa-viit.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none',textShadow: '2px 2px 4px black' }}>DSS</a>,
          imgUrl: projImg6,
        },
        // Add more objects for 'DSS' as needed
      ];
    }

    // Return an empty array or handle other cases as needed
    return [];
  };

  const handleTabSelect = (key) => {
    setSelectedTab(key);
  };

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                  <h2>Events</h2>
                  <p id="eventp">At VIIT, we take pride in hosting a myriad of events that showcase the talents, creativity, and hard work of our club members for students. Below, we present a visual journey through some of our remarkable events, highlighting the passion and dedication that define our college community.</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first" style={{ textShadow: '2px 2px 4px black' }}>Viz-A-Thon</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="section" style={{ textShadow: '2px 2px 4px black' }}>Perception</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third" style={{ textShadow: '2px 2px 4px black' }}>DSS</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? 'animate__animated animate__slideInUp' : ''}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {fetchDataForTab('first').map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                        <Row>
                          {fetchDataForTab('section').map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {fetchDataForTab('third').map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background" />
    </section>
  );
};
