import mah from "../assets/img/Mahalle.jpeg";
import san from "../assets/img/santosh.jpeg";
import suv from "../assets/img/suvarna.jpeg";
import rupam from "../assets/img/rupam.jpeg";
import vaibhavi from "../assets/img/vaibhavi.jpeg";
import subhojit from "../assets/img/Subhojit.jpeg";
import bhushan from "../assets/img/Bhushan.jpeg";
import riya from "../assets/img/Riya.jpeg";
import sarthak from "../assets/img/Sarthak.jpeg";
import hiya from "../assets/img/Hiya.jpeg";
import pranesh from "../assets/img/Pranesh.jpeg";
import rashmi from "../assets/img/rashmi.jpeg";
import rutuja from "../assets/img/Rutuja.jpeg";
import sushant from "../assets/img/sush.jpeg";
import nomaan from "../assets/img/noman.jpg";
import sakshi from "../assets/img/Sakshi.jpg";
import sid from "../assets/img/Sid.jpeg";
import sejal from "../assets/img/sejal.jpeg";
import saksh from "../assets/img/Sakshi1.jpg";
import min from "../assets/img/Minakshi.jpg";
import tej from "../assets/img/Tej.jpg";
import zin from "../assets/img/Zin.jpg";
import arya from "../assets/img/arya.jpeg";
import ayush from "../assets/img/Ayush.jpeg";
import pranav from "../assets/img/Pranav.jpg";
import anuj from "../assets/img/Anuj.jpeg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import link from "../assets/img/link.png";
import insta from "../assets/img/insta.png";
import face from "../assets/img/face.png";
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Faculty and Team</h2><br></br><br></br><hr></hr>
                        <p style={{color:'white',textShadow:'1px 1px 5px red'}}><b>Faculty Coordinators</b></p>
                        <div className="team-members">
                           
                          
                        <div className="item">
                                <img src={mah} alt="Image" />
                                <div class="contentbox">
                                <h5>Dr Parikshit Mahalle</h5>
                                <h6>Head of Department AI & DS</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/parikshit-mahalle-876061116/"><img src={link} alt="Icon" /></a>
                                    <a href="https://x.com/MahallePnm?s=20https://x.com/MahallePnm?s=20"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/aisa_viit/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                                </div>

                            </div>
                            
                          
                           
                        </div><br></br>
                        <div className="team-members">
                      
                            <div className="item">
                                <img src={san} alt="Image" />
                                <h5>Prof.Santosh Kumar</h5>
                                <h6>Assistant HOD & AiSA Faculty Incharge</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/21025kumar/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/dssant2021/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                          
                            <div className="item">
                                <img src={suv} alt="Image" />
                                <h5>Prof.Suvarna Bhagwat</h5>
                                <h6>AiSA Faculty Incharge</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/suvarnarbhagwat/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/suvarnarbhagwat/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                        </div><br></br><br></br><hr></hr>
                        <p style={{color:'white',textShadow:'1px 1px 5px red'}}><b>Core Team</b></p>
                        <div className="team-members">
                           
                          
                            <div className="item">
                                <img src={rupam} alt="Image" />
                                <h5>Rupam Patil</h5>
                                <h6>President</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/rupam-patil-470848223/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/__rupam__patil__/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                            
                          
                           
                        </div><br></br><br></br>
                        <div className="team-members">
                           
                            
                            <div className="item">
                                <img src={vaibhavi} alt="Image" />
                                <h5>Vaibhavi Pathak</h5>
                                <h6>Vice-President</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/vaibhavi-pathak-250552237/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/vaibhavi6045/"><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                            <div className="item">
                                <img src={subhojit} alt="Image" />
                                <h5>Subhojit Talukdar</h5>
                                <h6>Secretary</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/subhojit-talukdar-735630269/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/subhojitt_287/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>

                            <div className="item">
                                <img src={bhushan} alt="Image" />
                                <h5>Bhushan Sisode</h5>
                                <h6>Treasurer</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/bhushansisode/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/b.v.s01/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                        </div><br></br><hr></hr>
                        
                        <p style={{color:'white',textShadow:'1px 1px 5px red'}}><b>Human Resource Team</b></p>
                        <div className="team-members">
                           
                           
                            <div className="item">
                                <img src={riya} alt="Image" />
                                <h5>Riya Agarwal</h5>
                                <h6>Human Resource Manager</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/riya-agarwal-a9285b229/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/riiiyyeaahhh/"><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                            
                          
                           
                        </div><br></br><hr></hr>
                        <p style={{color:'white',textShadow:'1px 1px 5px red'}}><b>Event Team</b></p>
                        <div className="team-members">
                           
                            
                            <div className="item">
                                <img src={sarthak} alt="Image" />
                                <h5>Sarthak Rak</h5>
                                <h6>Event Management</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/sarthak-rak-205b7922a/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/sarthak_rak/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                            <div className="item">
                                <img src={hiya} alt="Image" />
                                <h5>Hiya Shivnani</h5>
                                <h6>TY Members</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/hiya-shivnani-05456520a/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/hiiyyaaaa_z/"><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                            <div className="item">
                                <img src={pranesh} alt="Image" />
                                <h5>Pranesh Puri</h5>
                                <h6>TY Members</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/pranesh-puri-a22b44272/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/puripranesh/"><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                        </div><br></br>
                        <br></br>
                        <div className="team-members">
                           
                            
                            <div className="item">
                                <img src={rashmi} alt="Image" />
                                <h5>Rashmi</h5>
                                <h6>TY Members</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/rashmi-sontakke/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/_rashmi115_/"><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                            <div className="item">
                                <img src={rutuja} alt="Image" />
                                <h5>Rutuja chakor</h5>
                                <h6>TY Members</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/company/aisaviit/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/aisa_viit/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                            
                           
                        </div><br></br><hr></hr>
                        <p style={{color:'white',textShadow:'1px 1px 5px red'}}><b>Technical Team</b></p>
                        <div className="team-members">
                           
                            
                            <div className="item">
                                <img src={sushant} alt="Image" />
                                <h5>Sushant Patil</h5>
                                <h6>Techinal Activity Incharge</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/sushantpatil03/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/sushant_patil_03/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                            <div className="item">
                                <img src={nomaan} alt="Image" />
                                <h5>Nomaan Bagwan</h5>
                                <h6>Techinal Activity Incharge</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/nomaan-bagwan-01562b229/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/nomaanbagwan_04/"><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                            <div className="item">
                                <img src={sakshi} alt="Image" />
                                <h5>Sakshi shendurkar</h5>
                                <h6>TY Members</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/sakshi-shendurkar-6565a91b8/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/aisa_viit/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                        </div>
                        <br></br><br></br><hr></hr>
                        <p style={{color:'white',textShadow:'1px 1px 5px red'}}><b>Publicity Team</b></p>
                        <div className="team-members">
                            <div className="item">
                                <img src={sid} alt="Image" />
                                <h5>Siddhika Kanawade</h5>
                                <h6>Publicity</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/siddhika-kanawade-9a2195272/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/sidhika_kanawade/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                      </div><br></br><hr></hr>
                      <p style={{color:'white',textShadow:'1px 1px 5px red'}}><b>Design Team</b></p>
                        <div className="team-members">
                            <div className="item">
                                <img src={sejal} alt="Image" />
                                <h5>Sejal Patil</h5>
                                <h6>Design</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/sejal-patil-7b7b7822a/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/sej_2208/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                            <div className="item">
                                <img src={min} alt="Image" />
                                <h5>Minakshi Haral</h5>
                                <h6>Design</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/minakshi-haral-36a571260/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/minuharal21/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                      </div><br></br><hr></hr>
                      <p style={{color:'white',textShadow:'1px 1px 5px red'}}><b>Documentation and Magazine Team</b></p>
                        <div className="team-members">
                            <div className="item">
                                <img src={tej} alt="Image" />
                                <h5>Tejashree Deshpande</h5>
                                <h6>Documentation & Magazine</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/tejashree-deshpande-aiml/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/aisa_viit/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                            <div className="item">
                                <img src={zin} alt="Image" />
                                <h5>Zinee Gulhane</h5>
                                <h6>TY Members</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/zinee-gulhane-570b7722a/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/zineegulhane/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                      </div><br></br><hr></hr>
                      <p style={{color:'white',textShadow:'1px 1px 5px red'}}><b>Content Team</b></p>
                        <div className="team-members">
                            <div className="item">
                                <img src={arya} alt="Image"  />
                                <h5>Arya Narsoo</h5>
                                <h6>Content</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/company/aisaviit/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/aisa_viit/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                            <div className="item">
                                <img src={saksh} alt="Image" />
                                <h5>Sakshi Joshi</h5>
                                <h6>TY Members</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/sakshi-joshi-dataanalyst/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/_sakshij_27/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                      </div><br></br><hr></hr>
                      <p style={{color:'white',textShadow:'1px 1px 5px red'}}><b>Video Creator Team</b></p>
                        <div className="team-members">
                            <div className="item">
                                <img src={ayush} alt="Image" />
                                <h5>Ayush Patne</h5>
                                <h6>Video Creator</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/ayushpatne/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/ayushxpatne/ "><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                            <div className="item">
                                <img src={anuj} alt="Image" />
                                <h5>Anuj Harshbodhi</h5>
                                <h6>Video Creator</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/anujmlexpert/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/anuj_harshbodhi/"><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                            <div className="item">
                                <img src={pranav} alt="Image" />
                                <h5>Pranav Wagholikar</h5>
                                <h6>TY Members</h6>
                                <div className="social-icons">
                                    <a href="https://www.linkedin.com/in/pranav-wagholikar-010b7822a/"><img src={link} alt="Icon" /></a>
                                    <a href="https://facebook.com/aisa_viit"><img src={face} alt="Icon" /></a>
                                    <a href="https://www.instagram.com/pranav___14/"><img src={insta} alt="Icon" /></a>
                                    
                                    </div>
                            </div>
                           
                      </div>
                    </div>
                </div>
            </div>
        </div>
        
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
