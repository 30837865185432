import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import utube from "../assets/img/utube.svg";
import twitter from "../assets/img/twitter.svg";
export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
    
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/company/aisaviit/"><img src={navIcon1} alt="Icon" /></a>
              <a href="https://twitter.com/aisa_viit"><img src={navIcon2} alt="Icon" /></a>
              <a href="https://www.instagram.com/aisa_viit/ "><img src={navIcon3} alt="Icon" /></a>
              <a href="https://www.youtube.com/@aisa_viit"><img src={utube} alt="Icon" /></a>
              <a href="https://twitter.com/aisa_viit?lang=en "><img src={twitter} alt="Icon" /></a>
            </div>
            <p>--AISA VIIT--</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
